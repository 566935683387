import { extendTheme } from "@chakra-ui/react";
import "@fontsource/archivo";

const theme = extendTheme({
  fonts: {
    archivo: "Archivo",
    subHeading: "Times New Roman",
  },
  textStyles: {
    h2: {
      'font-family': 'var(--chakra-fonts-subHeading)',
    },
    div:{
      'font-weight': '900'
    }
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  }
});


export default theme;