import { Flex } from "@chakra-ui/react";


function App() {
 
  return (
  
    <Flex height="100vh" alignItems="center" justifyContent="center" textAlign="center" textTransform="uppercase" fontFamily="archivo" fontWeight="900"  fontSize={{base:"16px", md:"24px"}}>
      Wadah Baku Hantam Online <br />Namun Santun Segera Mengudara
    </Flex>
    
  );
}
export default App;
